import consumer from "./consumer";

consumer.subscriptions.create("IntercomChannel", {
  connected() {
    console.log("Connected to IntercomChannel", consumer);
    // export consumer to console for dev:
    window.consumer = consumer; // TODO: remove this line
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.log("Disconnected from IntercomChannel");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log("Received data from IntercomChannel: ", data);
    // Called when there's incoming data on the websocket for this channel
  },
});
