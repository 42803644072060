import Rails from "@rails/ujs";
Rails.start();
// // require("@rails/activestorage").start()
import "@hotwired/turbo-rails";
require("channels");

import "@fortawesome/fontawesome-free/css/all";

console.log("Hello from application.js");

const reloadIfIdle = () => {
  const idleStatusElement = document.querySelector("[data-status=idle]");
  const currentUrl = window.location.href;
  if (idleStatusElement) {
    fetch(currentUrl)
      .then((response) => {
        if (response.ok) {
          console.log("will reload", response.ok);
          window.location.reload();
        } else {
          console.log("that url does not reply 200 OK", { currentUrl, response });
        }
      })
      .catch((err) => {
        console.log("fetch failed with:", err);
      });
  }
};
const interval = setInterval(reloadIfIdle, 5 * 60 * 1000);

document.addEventListener("turbo:load", () => {
  console.log("turbo:load");
});
